import React, { useState, useEffect } from "react";
import bg from "./utils/bg.svg";
import logo from "./utils/logo.png";
import instagramLogo from "./utils/insta.svg";
import "./utils/Boska-MediumItalic.otf";
import "./utils/LuxoraGrotesk-Light.ttf";

function App() {
  const [hover, setHover] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Track when all fonts are loaded
    const fontPromise = document.fonts.ready;

    // Load images
    const loadImage = (src) =>
      new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
      });

    const logoImage = loadImage(logo);
    const backgroundImage = loadImage(bg);

    // Wait for both images and fonts to load
    Promise.all([logoImage, backgroundImage, fontPromise]).then(() => {
      setIsLoaded(true); // Once everything is loaded, set the state
    });
  }, []);

  if (!isLoaded) {
    return <div className="loading-screen">Loading...</div>; // Optional: Add a loading screen
  }

  return (
    <div className="App">
      <div
        className="w-full h-screen bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="flex flex-col lg:pt-16 md:pt-36 pt-32 items-center h-full">
          <div>
            <img
              src={logo}
              className="lg:w-96 lg:mt-16 md:w-96 w-60 lg:-mb-12 md:-mb-12"
              alt="Logo"
            />
          </div>
          <div className="text text-center text-7xl">
            <p>We're</p>
            <p>Coming</p>
          </div>

          <div className="mt-8">
            <button
              className={`sub-text border border-black rounded-full lg:-mt-4 md:-mt-2 mt px-6 py-2 lg:text-xl md:text-lg text-md font-medium transition w-60 flex items-center justify-center ${
                hover ? "bg-black text-black" : "hover:bg-black hover:text-transparent"
              }`}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <a href="https://www.instagram.com/grakion_/">
                {hover ? (
                  <img
                    src={instagramLogo}
                    alt="Instagram"
                    className="w-6 h-6 text-gray-500"
                    style={{
                      filter:
                        "invert(70%) sepia(6%) saturate(5%) hue-rotate(200deg) brightness(92%) contrast(89%)",
                    }}
                  />
                ) : (
                  "explore on instagram"
                )}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
